<script>
export default{
    props:{ scores: { type: Array, default: new Array()} },
    data(){
        return { scoreRows: this.scores, };
    },
    watch: {
        scores: function(newValue) { this.scoreRows = newValue; }
    }
}
</script>


<template>
    <b-container v-if="scoreRows && scoreRows.length > 0">
        <b-row class="header ">
            <b-col cols="1">{{ $t('pages.competition-run.event-run.previous-scores.competitor-order') }}</b-col>
            <b-col cols="6">{{ $t('pages.competition-run.event-run.previous-scores.competitor-name') }}</b-col>
            <b-col cols="2">{{ $t('pages.competition-run.event-run.previous-scores.execution-score') }}</b-col>
            <b-col cols="2">{{ $t('pages.competition-run.event-run.previous-scores.content-score') }}</b-col>

        </b-row>
        <b-row v-for="score in scoreRows" :key="score.id">
            <b-col cols="1">{{ score.COMPETITOR.effective_order }}</b-col>
            <b-col cols="6">{{ score.COMPETITOR.shortname }}</b-col>
            <b-col cols="2">{{ score.execution }}</b-col>
            <b-col cols="2">{{ score.content }}</b-col>
        </b-row>
    </b-container>
</template>

<style scoped>
    .header{ font-weight:bolder;}
</style>